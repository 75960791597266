import 'bootstrap/dist/css/bootstrap.min.css';
import '@/styles/globals.scss';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { SSRProvider } from 'react-bootstrap';
import dynamic from 'next/dynamic';
const GlobalProvider = dynamic(() => import('@/components/context/Global').then(mod => mod.GlobalProvider));

const inter = Inter({ subsets: ['latin'], variable: '--font-inter-tight', });
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App({ Component, pageProps }: AppProps) {
  
  return (
    <>
      <style jsx global>{`
        body {
          font-family: ${inter.style.fontFamily} !important;
        }
      `}</style>
      <SSRProvider>
        <GlobalProvider>
          <ToastContainer/>
          <Component {...pageProps} />
        </GlobalProvider>
      </SSRProvider>
    </>
  );
}
